import BasicInput from '@archinsurance-viki/property-jslib/src/components/inputs/v2/BasicInput';
import cx from 'classnames';
import { BuildingGroupTermsSection } from './BuildingGroupSection';

const BuildingGroupInformation = () => (
    <div className="tw-flex tw-gap-4 tw-p-2 tw-items-center">
        <div className="tw-flex-1 tw-flex tw-gap-2 tw-items-center">
            <label>Group Name</label>
            <BasicInput className="tw-flex-1" value="Suburban Villas" />
        </div>
        <div className="tw-flex-1 tw-flex tw-gap-2 tw-items-center">
            <label>Description (Optional)</label>
            <BasicInput className="tw-flex-1" value="This building group is comprised of several residential properties in a suburban area" />
        </div>
    </div>
);

const AddBuildingsToGroup = () => (
    <div className="tw-flex tw-flex-col tw-gap-2">
        <div className="tw-flex tw-gap-4 tw-text-lg">
            <button className="tw-bg-blue-primary">Add Building(s) to Group</button>
            <button className="tw-bg-blue-primary">Select Peril Group</button>
            <button className="tw-bg-blue-primary">Group Buildings By</button>
        </div>
        <h2>Buildings added to the group</h2>
        <div className="grid-layout settings panelgeneral tw-grid tw-grid-cols-[50px_1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr] [&_div]:[border-bottom:1px_solid_#eaeaea_!important]">
            <a
                className={cx('tw-self-center tw-justify-center tw-bg-[rgba(240,_240,_240,_1)] material-icons tw-select-none tw-w-full', {
                    active: false,
                    'hover:tw-text-grey-checkbox': true,
                })}
                title="Select All"
            >
                done
            </a>
            <div className="data-cell tw-font-bold tw-bg-[rgba(240,_240,_240,_1)]">Name</div>
            <div className="data-cell tw-font-bold tw-bg-[rgba(240,_240,_240,_1)]">Address</div>
            <div className="data-cell tw-font-bold tw-bg-[rgba(240,_240,_240,_1)]">County</div>
            <div className="data-cell tw-font-bold tw-bg-[rgba(240,_240,_240,_1)]">State</div>
            <div className="data-cell tw-font-bold tw-bg-[rgba(240,_240,_240,_1)]">Occupancy</div>
            <div className="data-cell tw-font-bold tw-bg-[rgba(240,_240,_240,_1)]">Construction</div>
            <div className="data-cell tw-font-bold tw-bg-[rgba(240,_240,_240,_1)]">Peril</div>
            <div className="data-cell tw-font-bold tw-bg-[rgba(240,_240,_240,_1)]">Zip</div>
            <a
                className={cx('tw-self-center tw-justify-center material-icons tw-select-none tw-w-full', {
                    active: true,
                    'hover:tw-text-grey-checkbox': false,
                })}
                title="Select"
            >
                done
            </a>
            <div className="data-cell">Riverfront Plaza</div>
            <div className="data-cell">101 Riverfront Drive</div>
            <div className="data-cell">Riverdale</div>
            <div className="data-cell">NJ</div>
            <div className="data-cell">Mixed-Use</div>
            <div className="data-cell">Steel & Concrete</div>
            <div className="data-cell">Earthquake</div>
            <div className="data-cell [border-right:1px_solid_#eaeaea_!important]">27545</div>
        </div>
        <div className="tw-flex tw-justify-end tw-gap-4 tw-text-lg">
            <button className="tw-bg-red-primary">Remove Building(s)</button>
            <button className="tw-bg-blue-primary">Building Details</button>
        </div>
    </div>
);

export const BuildingGroupDetails = () => (
    <div className="tw-flex tw-flex-col tw-gap-2">
        <BuildingGroupTermsSection groupKey="INFORMATION">
            <BuildingGroupInformation />
        </BuildingGroupTermsSection>
        <AddBuildingsToGroup />
    </div>
);
