import _ from 'lodash';
import { NavLink, useRouteMatch } from 'react-router-dom';
import cx from 'classnames';

import classNames from 'classnames';
import { applyParamsToPath } from '@archinsurance-viki/property-jslib/src/utils/js-helpers';
import { NavItemType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { useAppContext } from '../../hooks/context';
import { useAppSelector } from '../../hooks/redux';
import { Types } from '../../ts-types/viki-types';
import { usePolicyTermsDetailGlossaryQuery } from '../../services/endpoints/coverage-terms';

type propTypes = {
    level: number;
    navItem: NavItemType;
};

export const ScrollSpyLeftPanelOption = ({ subNavItem }: { subNavItem: NavItemType }) => {
    const active = window.location.hash === subNavItem.url;

    return (
        <div className="panel-option expandable level-4">
            <a className={cx('nav-link', { active })} href={subNavItem.url} style={{ display: 'block' }}>
                <div className="nav-header">{subNavItem.name}</div>
            </a>
        </div>
    );
};

export default function LeftPanelOption(props: propTypes) {
    const { level, navItem } = props;
    const { subNavItems, url } = navItem;

    const { currentQuote } = useAppContext();
    const { data: policyTermsGlossary } = usePolicyTermsDetailGlossaryQuery(
        { id: currentQuote?.policy_coverage_id },
        { skip: !currentQuote?.policy_coverage_id }
    );

    const appContext = useAppContext();
    const featureFlags = useAppSelector(state => state.global.featureFlags);
    const tenantFlags = useAppSelector(state => state.global.ENV.TENANT_SETTINGS.features) as Types.TenantFlags;
    const POLICY_TERMS_GROUPS = useAppSelector(state => state.global.CONSTANTS.POLICY_TERMS_GROUPS);
    const me = useAppSelector(state => state.global.me);
    const { currentAccountId, currentQuoteId, currentSubmissionId, currentSubmissionShortcut, currentTransactionId } = appContext;

    let subNavId: string = null;
    let subNavId2: string = null;

    const match = useRouteMatch();
    if (!match) {
        return null;
    }

    const policyTermsSectionIds = {};
    Object.keys(POLICY_TERMS_GROUPS).forEach(groupKey => {
        const glossaryKey = `group_${groupKey.toLocaleLowerCase()}`;
        if (policyTermsGlossary?.[glossaryKey]?.active !== false) {
            policyTermsSectionIds[groupKey] = groupKey;
        }
    });

    const navItemProps = { policyTermsSectionIds, featureFlags, appContext, me, tenantFlags };

    const isActive = (typeof navItem.isActive === 'function' ? navItem.isActive(navItemProps) : navItem.isActive) || match.path.indexOf(url) >= 0;
    let isExpanded = !!isActive || navItem.isExpanded;
    if (navItem.subItemIdGetter) {
        subNavId = match.params[navItem.subItemIdGetter];
        if (subNavId) {
            if (navItem.subItemIdGetter2) {
                subNavId2 = navItem.subItemIdGetter2(match.params);
            }
            isExpanded = isExpanded || (subNavItems && !!subNavItems.length);
        }
    } else {
        isExpanded = isExpanded || (subNavItems && !!subNavItems.length && isActive);
    }

    const className = classNames('panel-option', 'expandable', `level-${level}`, { expanded: isExpanded });
    const renderedSubNavItems = [];

    if ((isExpanded || navItem.isScrollSpyEnabled) && subNavItems && subNavItems.length) {
        for (const s of subNavItems) {
            const subNavItem = _.clone(s);
            const visible = typeof subNavItem.visible === 'function' ? subNavItem.visible(navItemProps) : subNavItem.visible;

            if (visible !== undefined && !visible) {
                continue;
            }
            if (!subNavItem.isExternal && !subNavItem.isAnchor && !navItem?.isScrollSpyEnabled) {
                subNavItem.url = url + subNavItem.url;
            }

            if (navItem?.isScrollSpyEnabled) {
                renderedSubNavItems.push(<ScrollSpyLeftPanelOption key={subNavItem.url} subNavItem={subNavItem} />);
            } else renderedSubNavItems.push(<LeftPanelOption key={subNavItem.url} navItem={subNavItem} level={level + 1} />);
        }
    }

    const submissionSlug = currentAccountId && currentSubmissionShortcut ? `${currentAccountId}/${currentSubmissionShortcut}` : null;

    const urlContext = Object.assign(
        {},
        {
            accountId: currentAccountId,
            quoteId: currentQuoteId,
            submissionId: currentSubmissionId,
            submissionSlug: submissionSlug,
            transactionId: currentTransactionId,
        }
    );

    let navUrl = applyParamsToPath(!navItem.urlOverride ? url : url + navItem.urlOverride, urlContext);

    if (!navItem.isExternal && subNavId) {
        // If we matching but have more than the current url then we want to keep the id.
        let newUrl = `${navUrl}/${subNavId}`;
        if (subNavId2) {
            newUrl += `/${subNavId2}`;
        }
        if (match.path.indexOf(url) >= 0 && match.url !== newUrl) {
            navUrl = newUrl;
        }
    }

    const visible = typeof navItem.visible === 'function' ? navItem.visible(navItemProps) : navItem.visible;
    if (navItem.visible === undefined) {
        console.error(`navItem.visible is undefined for`, navItem);
    }
    const linkContent = (
        <div className="nav-header">
            {typeof navItem.name === 'function' ? navItem.name(navItemProps) : navItem.name}
            <If condition={!!navItem.icon}>
                <i className="material-icons md-18">{navItem.icon || ''}</i>
            </If>
        </div>
    );

    let link: React.ReactNode = null;
    if (navItem.isExternal) {
        link = (
            <a
                className={classNames('nav-link external', { active: isActive })}
                href={navUrl}
                target={navItem.sameTab ? '_self' : '_blank'}
                rel="noopener noreferrer"
            >
                {linkContent}
            </a>
        );
    } else if (navItem.isAnchor) {
        link = (
            <a className={classNames('nav-link anchor', { active: isActive })} href={url}>
                {linkContent}
            </a>
        );
    } else {
        link = (
            <NavLink style={{ display: visible ? 'block' : 'none' }} className="nav-link" activeClassName="active" to={navUrl}>
                {linkContent}
            </NavLink>
        );
    }

    return (
        <div className={className}>
            {link}
            {navItem?.isScrollSpyEnabled ? renderedSubNavItems : <div className="sub-nav">{renderedSubNavItems}</div>}
        </div>
    );
}
