// TODO: React compiler - fix the callback TODO so we can re-eanble compiler for this component
/* eslint-disable react-compiler/react-compiler */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createTransaction } from '../../../../actions/EndorsementActions';
import { RootState } from '../../../../store';
import { AppContextType } from '../../../../ts-types/AppTypes';
import { QUOTE_STATUSES } from '../../../../constants/Quote';
import { REWRITE_AND_REVISION_TRANSACTION_TYPES, STUB_SUBMISSION_TRANSACTION_TYPES, TRANSACTION_TYPES } from '../../../../constants/TransactionConstants';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { UiStateType, updateUi } from '@archinsurance-viki/property-jslib/src/reducers/ui_state';
import { Paths, TypeFromPath } from '@archinsurance-viki/property-jslib/src/ts-types/util';
import { useAppContext } from '../../../../hooks/context';
import { NO_ACCOUNT } from '../../../../reducers/accounts';
import { DateInput, TextAreaInput, CheckInput, ComboBoxInput, RadioInputGroup } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { DISPLAY_DATE_FORMAT } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { OnEveryChangeType } from '@archinsurance-viki/property-jslib/src/ts-types/InputTypes';
import _ from 'lodash';
import moment from 'moment';
import { TableRowDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { useAccountTransactionDataQuery } from '../../../../services/endpoints/account';

type EndorsementObjectUpdateFields = Omit<UiStateType['endorsementObj'], '_errors'>;

type TransactionTypeOptions = {
    inputProps: {
        label: string;
        // TODO: Maybe get rid of the fake transaction types?
        value: (typeof TRANSACTION_TYPES)[keyof typeof TRANSACTION_TYPES] | 'NPBEN' | 'CANCEL_REWRITE' | 'BINDER_REVISION' | 'UNDO';
        visible: boolean;
        fields: (keyof EndorsementObjectUpdateFields | 'coverage_option')[];
        disabled?: boolean;
        disabledReasons?: string[];
    };
};

type ownPropTypes = {
    appContext: AppContextType;
    onClose: (x?: boolean) => any;
    onSetCurrentSubmission: (aId?: number, sId?: string | number, replace?: boolean, subRoute?: string | null, params?: Record<string, any>) => void;
};

const mapStateToProps = (state: RootState, ownProps: ownPropTypes) => {
    let quoteData = state.quotes.rowData;
    let { appContext } = ownProps;
    let { currentSubmissionId, currentTransaction } = appContext;
    let revertOrReinstatementTransaction = (STUB_SUBMISSION_TRANSACTION_TYPES as string[]).includes(currentTransaction.transaction_type);

    let quoteDescriptions: { value: number; display: string }[], originalPrimaryQuoteId: number;
    if (revertOrReinstatementTransaction) {
        quoteDescriptions = [{ value: 1, display: 'This feature is disabled for reversions/reinstatements.' }];
        originalPrimaryQuoteId = 1;
    } else {
        let quoteIds = state.submissionLog.rowData[currentSubmissionId].quotes as number[];
        let primaryQuoteId = appContext.currentSubmission.primary_quote_id;
        let effectiveDate = quoteData[primaryQuoteId]?.policy_coverage?.effective_date;
        let quotes: Record<string, TableRowDataType> = {};
        for (const qid of quoteIds) {
            let rowData = quoteData[qid];

            if (
                qid === primaryQuoteId ||
                (![QUOTE_STATUSES.UNKNOWN, QUOTE_STATUSES.IN_PROGRESS].includes(rowData?.original_quote_status) &&
                    rowData.policy_coverage.effective_date === effectiveDate)
            ) {
                quotes[qid] = rowData;
            }
        }
        quoteDescriptions = Object.keys(quotes).map(qid => {
            let quote = quotes[qid];
            let original_quote_id = quote['original_quote_id'] as number;
            let description = `${original_quote_id}: ${quote['description']}`;
            return { value: original_quote_id, display: description };
        });
        originalPrimaryQuoteId = quotes[primaryQuoteId]['original_quote_id'] as number;
    }

    return {
        uiState: state.uiState,
        featureFlags: state.global.featureFlags,
        quoteDescriptions: quoteDescriptions,
        originalPrimaryQuoteId: originalPrimaryQuoteId,
        revertOrReinstatementTransaction: revertOrReinstatementTransaction,
    };
};

const InitiateEndorsementApp = (props: ownPropTypes) => {
    const dispatch = useAppDispatch();
    const appContext = useAppContext();
    const mappedState = useAppSelector(state => mapStateToProps(state, props));
    const endorsementObj = useAppSelector(state => state.uiState.endorsementObj);
    const calculatedProps = useMemo(
        () => ({
            ...props,
            ...mappedState,
        }),
        [mappedState, props]
    );

    const [isSaving, setIsSaving] = useState(false);
    const [isSavable, setIsSavable] = useState(false);
    const [statusMessage, setStatusMessage] = useState<string | React.ReactNode>('');
    const [currentCoverageOption, setCurrentCoverageOption] = useState<number>(null);
    const defaultCoverageOption = calculatedProps.originalPrimaryQuoteId;

    const { data: accountTransactionData } = useAccountTransactionDataQuery(
        { submissionId: appContext.currentSubmissionId },
        { skip: !appContext?.currentSubmissionId }
    );

    const onCreateTransaction = (id: number, kwargs: Record<string, unknown>) => {
        return dispatch(createTransaction(id, kwargs));
    };

    const handleCoverageOptionChange = (fieldName: 'change_primary_quote_to', value: number): void => {
        handleChange(fieldName, value);
        setCurrentCoverageOption(value);
        if (currentCoverageOption !== defaultCoverageOption) {
            handleChange('is_premium_bearing', true);
        }
    };

    const onSetTransactionType = (value: string): void => {
        let changes: Partial<UiStateType['endorsementObj']> = { transaction_type: value };
        if (value === 'CFL') {
            changes.return_modeling_fees = true;
            changes.return_inspection_fees = true;
        } else if (value === 'CPR') {
            changes.return_modeling_fees = false;
            changes.return_inspection_fees = false;
        }
        changes.validate_for_premium_return = false;

        _updateUIState(changes);

        // Remove transaction type errors
        const { transaction_type, ...errors } = endorsementObj._errors;
        dispatch(updateUi({ data: errors, dataPath: 'endorsementObj._errors', shouldReplace: true }));
    };

    const _updateUIState = (newEndorsementObj: Partial<EndorsementObjectUpdateFields>) => {
        dispatch(updateUi({ data: { endorsementObj: newEndorsementObj } }));
        let isSavable = !!newEndorsementObj.transaction_description;

        if (newEndorsementObj.transaction_type === 'EN') {
            isSavable = isSavable && !!newEndorsementObj.effective_date;
        } else if (newEndorsementObj.transaction_type === 'CPR') {
            isSavable = isSavable && !!newEndorsementObj.cancellation_date;
        }

        setIsSavable(isSavable);
    };

    const handleChange = <Path extends Paths<EndorsementObjectUpdateFields>>(fieldName: Path, value: TypeFromPath<EndorsementObjectUpdateFields, Path>) => {
        let newEndorsementObj = { ...endorsementObj, [fieldName]: value };
        _updateUIState(newEndorsementObj);

        // Remove transaction type errors
        const { _errors: errors } = endorsementObj;
        errors && delete errors[fieldName];
        dispatch(updateUi({ data: errors, dataPath: 'endorsementObj._errors', shouldReplace: true }));
    };

    const handleClose = () => {
        _clearForm();
        props.onClose();
    };

    const handleInitiateEndorsement = () => {
        let { currentAccountId, currentSubmissionId } = appContext;
        let submissionOrAccountId = currentAccountId || currentSubmissionId;
        let statusMessage = <span className="green-txt">Saving...</span>;
        setStatusMessage(statusMessage);
        setIsSaving(true);
        onCreateTransaction(submissionOrAccountId, endorsementObj).catch(error => {
            setIsSaving(false);
            let statusMessage = <span className="red-txt">{'Error: ' + (error.data ? JSON.stringify(error.data) : 'Unknown errors trying to initiate.')}</span>;
            setStatusMessage(statusMessage);
        });
    };

    const getTransactionTypeOptions = useCallback(() => {
        const { currentTransaction, currentAccount, featureFlags, transactions } = appContext;

        // const prevTransactionIsCancel = currentAccount.most_recent_committed_transaction.is_cancellation;
        const prevTransactionIsReversion = currentAccount.most_recent_committed_transaction.is_reversion;
        const isCurrentlyCancelled = !!currentAccount.cancellation_date;
        // const isCurrentlyCancelled = prevTransactionIsCancel; // TODO: Not quite right, if you NPB-transact the cancellation.
        const isPolicyIssued = accountTransactionData.is_issued;
        const isRewriteOrBinderRevision = (REWRITE_AND_REVISION_TRANSACTION_TYPES as string[]).includes(currentTransaction.transaction_type);

        let revert_of_transaction = currentAccount.most_recent_unreverted_transaction;

        // undo: revert most-recent reversion (rather than the most-recent unreverted thing).
        let undoTransaction = null;
        if (prevTransactionIsReversion && currentAccount.most_recent_committed_transaction.id !== (revert_of_transaction && revert_of_transaction.id))
            undoTransaction = currentAccount.most_recent_committed_transaction;

        const isSomethingInReview = !!transactions.find(tx => tx.status === 'IN_REVIEW');
        const isSomethingInReviewReasons = isSomethingInReview ? ['Disabled - tx already in review.'] : null;

        const disableCFL = isSomethingInReview || !currentAccount._actions.flat_cancellable;
        // const disableCFLReasons = !currentAccount._actions.flat_cancellable ? ['Disabled - Flat Cancel Not Applicable'] : isSomethingInReviewReasons;

        let INPUT_GROUP_LIST: TransactionTypeOptions[] = null;
        if (featureFlags.enable_cancellations && !currentAccount.uses_legacy_cancellations) {
            INPUT_GROUP_LIST = [
                {
                    inputProps: {
                        label: 'Endorsement',
                        value: 'EN',
                        visible: !isCurrentlyCancelled && isPolicyIssued,
                        fields: ['effective_date', 'validate_for_premium_return', 'transaction_description'],
                    },
                },
                {
                    inputProps: {
                        label: 'Non-premium bearing Endorsement',
                        value: 'NPBEN',
                        visible: !isCurrentlyCancelled && isPolicyIssued,
                        fields: ['effective_date', 'transaction_description'],
                    },
                },
                {
                    inputProps: {
                        label: 'Flat Cancellation',
                        value: 'CFL',
                        visible: !isCurrentlyCancelled && !disableCFL,
                        disabled: isSomethingInReview,
                        disabledReasons: isSomethingInReviewReasons,
                        fields: ['return_modeling_fees', 'return_inspection_fees', 'transaction_description'],
                    },
                },
                {
                    inputProps: {
                        label: 'Pro-rata Cancellation',
                        value: 'CPR',
                        visible: !isCurrentlyCancelled,
                        disabled: isSomethingInReview,
                        disabledReasons: isSomethingInReviewReasons,
                        fields: ['cancellation_date', 'use_mep', 'return_modeling_fees', 'return_inspection_fees', 'transaction_description'],
                    },
                },
                {
                    inputProps: {
                        label: 'Cancel/Rewrite',
                        value: 'CANCEL_REWRITE',
                        visible: !isCurrentlyCancelled && isPolicyIssued && featureFlags.enable_cancel_rewrite && !disableCFL,
                        disabled: isSomethingInReview,
                        disabledReasons: isSomethingInReviewReasons,
                        fields: ['generate_new_policy_number', 'transaction_description', 'coverage_option'],
                    },
                },
                {
                    inputProps: {
                        label: 'Binder Revision',
                        value: 'BINDER_REVISION',
                        visible: !isCurrentlyCancelled && featureFlags.enable_binder_revision && !isPolicyIssued,
                        disabled: isSomethingInReview,
                        disabledReasons: isSomethingInReviewReasons,
                        fields: ['transaction_description', 'is_premium_bearing', 'coverage_option'],
                    },
                },
                {
                    inputProps: {
                        label: 'Reinstatement',
                        value: 'RET',
                        visible: isCurrentlyCancelled,
                        disabled: isSomethingInReview,
                        disabledReasons: isSomethingInReviewReasons,
                        fields: ['transaction_description'],
                    },
                },
                {
                    inputProps: {
                        label: `Revert Transaction ${revert_of_transaction && revert_of_transaction.id}, reinstating Transaction ${
                            revert_of_transaction && revert_of_transaction.previous_transaction_id
                        }`,
                        value: 'REV',
                        visible: revert_of_transaction && revert_of_transaction.previous_transaction_id && !isRewriteOrBinderRevision,
                        disabled: isSomethingInReview,
                        disabledReasons: isSomethingInReviewReasons,
                        fields: ['transaction_description'],
                    },
                },
                {
                    inputProps: {
                        label: `Undo Reversion ${undoTransaction && undoTransaction.id}, reinstating Transaction ${
                            undoTransaction && undoTransaction.rollback_of_id
                        }`,
                        value: 'UNDO',
                        visible: !!undoTransaction,
                        disabled: isSomethingInReview,
                        disabledReasons: isSomethingInReviewReasons,
                        fields: ['transaction_description'],
                    },
                },
                {
                    // This one should always be at the bottom. Put new transactions above this one.
                    inputProps: {
                        label: 'Account Correction',
                        value: 'COR',
                        visible: featureFlags.enable_correction_transaction && !isCurrentlyCancelled && isPolicyIssued,
                        fields: ['effective_date', 'transaction_description'],
                    },
                },
            ];
        } else {
            INPUT_GROUP_LIST = [
                {
                    inputProps: {
                        label: 'Endorsement',
                        value: 'EN',
                        visible: true,
                        fields: ['effective_date', 'transaction_description'],
                    },
                },
                {
                    inputProps: {
                        label: 'Non-premium bearing Endorsement',
                        value: 'NPBEN',
                        visible: true,
                        fields: ['effective_date', 'transaction_description'],
                    },
                },
                {
                    inputProps: {
                        label: 'Account Correction',
                        value: 'COR',
                        visible: !isCurrentlyCancelled && isPolicyIssued,
                        fields: ['effective_date', 'transaction_description'],
                    },
                },
            ];
        }
        // add disabled msg to each label.
        _.each(
            INPUT_GROUP_LIST,
            i => (i.inputProps.label = i.inputProps.disabled ? `${i.inputProps.label} (${i.inputProps.disabledReasons.join()})` : i.inputProps.label)
        );

        return _.filter(INPUT_GROUP_LIST, i => i.inputProps.visible) as TransactionTypeOptions[];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // TODO: This shouldn't be a callback, it should be a separate component

    const _clearForm = useCallback(() => {
        setIsSaving(false);
        setIsSavable(false);
        setStatusMessage('');

        const transactionTypeOptions = getTransactionTypeOptions();
        let transactionType: (typeof transactionTypeOptions)[number];
        if (transactionTypeOptions.length > 0) {
            transactionType = transactionTypeOptions[0];
        }

        dispatch(
            updateUi({
                data: {
                    endorsementObj: {
                        transaction_type: transactionType ? transactionType.inputProps.value : null,
                        effective_date: null,
                        transaction_description: '',
                        is_premium_bearing: true,
                        use_mep: true,
                        generate_new_policy_number: false,
                        return_modeling_fees: true,
                        return_inspection_fees: true,
                        validate_for_premium_return: false,
                        change_primary_quote_to: null,
                    },
                },
            })
        );
    }, [dispatch, getTransactionTypeOptions]);

    useEffect(() => {
        _clearForm();
    }, [_clearForm]);

    let format = { dateDisplayFormat: DISPLAY_DATE_FORMAT };
    const { quoteDescriptions, originalPrimaryQuoteId, revertOrReinstatementTransaction } = calculatedProps;
    const { currentAccount, currentSubmission, featureFlags } = appContext;
    let endOfCurrentPolicy, startOfCurrentPolicy, earliestCancelStartDate, earliestProRataCancelStartDate;
    let changingCoverageOption = !!(currentCoverageOption && currentCoverageOption !== defaultCoverageOption);
    let enableChangeCoverageOption = featureFlags.enable_transaction_change_coverage_option;

    if (!endorsementObj || !endorsementObj.transaction_type) return null;

    if (currentAccount && currentAccount.id !== NO_ACCOUNT) {
        let { account_transaction_results_obj } = currentAccount.most_recent_committed_transaction;
        endOfCurrentPolicy = account_transaction_results_obj.expiration_date;
        startOfCurrentPolicy = account_transaction_results_obj.effective_date;
        earliestCancelStartDate = currentAccount.earliest_acceptable_cancellation_date;
    } else {
        startOfCurrentPolicy = currentSubmission.policy_inception_date;
        endOfCurrentPolicy = currentSubmission.policy_expiration_date;
        earliestCancelStartDate = startOfCurrentPolicy;
    }

    earliestProRataCancelStartDate =
        startOfCurrentPolicy === earliestCancelStartDate
            ? moment(earliestCancelStartDate, 'YYYY-MM-DD').add(1, 'day').toDate()
            : moment(earliestCancelStartDate, 'YYYY-MM-DD').toDate();

    const transactionTypeOptions = getTransactionTypeOptions();

    const checkProps = { labelAfter: true, className: 'width-20', object: endorsementObj, defaultValue: true, onChange: handleChange };
    const selectedControlType = _.find(transactionTypeOptions, (i: Record<string, any>) => i.inputProps.value === endorsementObj.transaction_type);
    const controlNames = selectedControlType ? selectedControlType.inputProps.fields : [];

    const ALL_CONTROLS = {
        effective_date: (
            <DateInput
                format={format}
                key="effdate"
                name="effective_date"
                label="Endorsement Effective Date"
                onChange={handleChange}
                minDate={endorsementObj.validate_for_premium_return ? moment(earliestCancelStartDate, 'YYYY-MM-DD').toDate() : startOfCurrentPolicy}
                maxDate={moment(endOfCurrentPolicy, 'YYYY-MM-DD').toDate()}
                object={endorsementObj}
            />
        ),
        cancellation_date: (
            <DateInput
                format={format}
                key="canceldate"
                name="cancellation_date"
                label="Cancellation Date"
                onChange={handleChange}
                minDate={earliestProRataCancelStartDate}
                maxDate={moment(endOfCurrentPolicy, 'YYYY-MM-DD').toDate()}
                object={endorsementObj}
            />
        ),
        transaction_description: (
            <TextAreaInput
                name="transaction_description"
                key="ed"
                label="Description"
                onEveryChange={handleChange as OnEveryChangeType}
                object={endorsementObj}
            />
        ),
        use_mep: <CheckInput {...checkProps} key="mep" label="Use Minimum Earned Premium" name="use_mep" defaultValue={true} />,
        generate_new_policy_number: (
            <CheckInput
                {...checkProps}
                key="generate_new_policy_number"
                label="Generate a new Policy Number"
                name="generate_new_policy_number"
                defaultValue={changingCoverageOption}
            />
        ),
        return_modeling_fees: <CheckInput {...checkProps} key="rmf" label="Return Modeling Fees" name="return_modeling_fees" defaultValue={true} />,
        return_inspection_fees: <CheckInput {...checkProps} key="rif" label="Return Inspection Fees" name="return_inspection_fees" defaultValue={true} />,
        validate_for_premium_return: (
            <CheckInput
                {...checkProps}
                key="validate_for_premium_return"
                label="Validate for Premium Return"
                name="validate_for_premium_return"
                defaultValue={false}
            />
        ),
        is_premium_bearing: <CheckInput {...checkProps} key="ipb" label="Is Premium Bearing?" name="is_premium_bearing" disabled={changingCoverageOption} />,
        coverage_option: (
            <ComboBoxInput
                key="co"
                label="Coverage Option"
                name="change_primary_quote_to"
                selectProps={{ choices: quoteDescriptions }}
                defaultValue={originalPrimaryQuoteId}
                object={endorsementObj}
                disabled={!enableChangeCoverageOption || revertOrReinstatementTransaction}
                disabledReason={
                    !enableChangeCoverageOption
                        ? 'This feature is disabled by feature flag until finished.'
                        : 'You can not change the coverage option from a revert/reinstatement transaction. Please select the transaction that was reinstated/reverted to and initiate the transaction from there.'
                }
                onChange={handleCoverageOptionChange}
            />
        ),
    };

    const controls = _.map(controlNames, name => ALL_CONTROLS[name]);

    return (
        <React.Fragment>
            <div className="standard-modal-content flex column narrow">
                <RadioInputGroup object={endorsementObj} name="transaction_type" inputGroupList={transactionTypeOptions} onChange={onSetTransactionType} />
                <div className="divider-line dl-margin-default"></div>
                {controls}
            </div>
            <div className="button-row">
                <button className="green" onClick={handleInitiateEndorsement} disabled={isSaving || !isSavable}>
                    Ok
                </button>
                <div className="spacer wide" />
                <button className="red" onClick={handleClose} disabled={isSaving}>
                    Discard
                </button>
            </div>
            <div className="pad-bottom-05 center">{statusMessage}</div>
        </React.Fragment>
    );
};

export default InitiateEndorsementApp;
