import { subMonths, endOfMonth } from 'date-fns';
import { Toast } from 'primereact/toast';
import { useEffect, useMemo, useRef } from 'react';
import { FieldErrors, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useQueryParams, NumberParam, StringParam, DateParam, decodeQueryParams } from 'use-query-params';
import { CommaArrayParam } from '@archinsurance-viki/property-jslib/src/utils/query-params';
import BasicButton from '@archinsurance-viki/property-jslib/src/components/buttons/BasicButton';
import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { FormDateInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormDateInput';
import { FormSelect } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormSelect';
import PanelArea from './PanelArea';
import { TaskTypePanel } from '../../features/AccountTasksPanel';
import { useAppSelector } from '../../hooks/redux';
import { DefaultAccountLayout } from '../common/DefaultLayout';
import { useLazyExportCatDataQuery } from '../../services/endpoints/cattools';
import { CattoolsExportParams } from '../../ts-types/ApiTypes';
import { formatDate, formatDateString } from '../../utils/form-utils';
import { ErrorToast, ToastMessage } from '../common/ToastMessage';
import { useChangeEffect } from '@archinsurance-viki/property-jslib/src/hooks/util';

type FormValues = CattoolsExportParams;

const ALL_CARRIERS_ID = 0;
const DEFAULT_IN_FORCE_DATE = endOfMonth(subMonths(new Date(), 1));

const QUERY_PARAM_MAP = {
    business_unit: StringParam,
    carrier_id: NumberParam,
    in_force_date: DateParam,
    cat_view_type: StringParam,
    policy_numbers: CommaArrayParam,
    inception_date_before: DateParam,
    inception_date_after: DateParam,
    expiration_date_before: DateParam,
    expiration_date_after: DateParam,
    lob_format: StringParam,
    cat_model_type: StringParam,
};

export const CattoolsPage = () => {
    const businessUnits = useAppSelector(state => state.global.CONSTANTS.BUSINESS_UNITS ?? []);
    const carriers = useAppSelector(state => state.global.CONSTANTS.CARRIERS ?? []);
    const catModelChoices = useAppSelector(state => state.global.CONSTANTS.CAT_MODEL_TYPES ?? []);
    const catModelChoicesMap = useAppSelector(state => state.global.CONSTANTS.CAT_MODEL_TYPES_MAP ?? {});
    const catViewChoices = useAppSelector(state => state.global.CONSTANTS.CAT_VIEW_TYPES ?? []);
    const lobFormatChoices = useAppSelector(state => state.global.CONSTANTS.EXPORT_LOB_FORMAT ?? []);
    const [exportCatData, { isLoading: isSubmitting }] = useLazyExportCatDataQuery();
    const toast = useRef<Toast>();

    const [params, setParams] = useQueryParams(QUERY_PARAM_MAP);

    const activeCarriers = useMemo(() => {
        const year = (params.in_force_date ? new Date(params.in_force_date) : DEFAULT_IN_FORCE_DATE).getFullYear();
        return carriers
            .filter(c => {
                const { activated_on, deactivated_on } = c;
                const start = new Date(year, 0, 1);
                const end = new Date(year + 1, 0, 0);
                // TODO: Is deactivated_on inclusive?
                // Filter for carriers that were active in the desired time range
                // Special cases
                //   `deactivated_on` being empty means it was never deactivated
                //   `activated_on` being empty means it was activated since beginning of time
                return (!deactivated_on || start <= new Date(deactivated_on)) && (!activated_on || end >= new Date(activated_on));
            })
            .map(c => {
                const { id, name, short_name } = c;
                return { value: id, display: `${short_name}: ${name}` };
            });
    }, [carriers, params.in_force_date]);

    // this function is inline because i was too lazy to figure out type of queryParams
    const getDefaultValues = (queryParams: Partial<typeof params>) => ({
        business_unit: queryParams.business_unit ?? businessUnits.find(({ value }) => value === 'VENT')?.value ?? '',
        cat_model_type: queryParams.cat_model_type ?? catModelChoices.find(({ value }) => value === 'AIR')?.value ?? '',
        cat_view_type: queryParams.cat_view_type ?? catViewChoices.find(({ value }) => value === 'P')?.value ?? '',
        in_force_date: formatDate.display(queryParams.in_force_date ?? DEFAULT_IN_FORCE_DATE),
        policy_numbers: (queryParams.policy_numbers ?? []).join(','),
        inception_date_after: formatDate.display(queryParams.inception_date_after ?? null),
        inception_date_before: formatDate.display(queryParams.inception_date_before ?? null),
        expiration_date_after: formatDate.display(queryParams.expiration_date_after ?? null),
        expiration_date_before: formatDate.display(queryParams.expiration_date_before ?? null),
        carrier_id: queryParams.carrier_id ?? ALL_CARRIERS_ID,
        lob_format: queryParams.lob_format ?? lobFormatChoices.find(({ value }) => value === 'PC')?.value ?? '',
    });

    const formMethods = useForm<FormValues>({
        defaultValues: getDefaultValues(params),
    });

    const { control, getValues, setValue, reset } = formMethods;
    const form = useWatch({ control });
    const inception_dates = useWatch({ control, name: ['inception_date_after', 'inception_date_before'] }).map(dateString =>
        dateString ? new Date(dateString) : null
    );
    const expiration_dates = useWatch({ control, name: ['expiration_date_after', 'expiration_date_before'] }).map(dateString =>
        dateString ? new Date(dateString) : null
    );
    const businessUnit = useWatch({ control, name: 'business_unit' });
    const currentBU = form.business_unit;
    const isVentusBU = currentBU === 'VENT';

    useChangeEffect(form, nextForm => {
        // re-use decoding in use-query-param as much as possible
        // dates are tricky as the library expects them in a certain format, so we can de-code on our own
        const decoded = decodeQueryParams(QUERY_PARAM_MAP, {
            ...nextForm,
            carrier_id: `${form.carrier_id}`,
        });
        setParams({
            ...decoded,
            in_force_date: formatDate.submit(getValues('in_force_date')),
            inception_date_after: inception_dates[0],
            inception_date_before: inception_dates[1],
            expiration_date_after: expiration_dates[0],
            expiration_date_before: expiration_dates[1],
        });
    });

    useEffect(() => {
        // the available active carriers can change depending on in force date
        const currentCarrierId = getValues('carrier_id');
        if (currentCarrierId !== ALL_CARRIERS_ID && !activeCarriers.find(c => c.value === currentCarrierId)) {
            setValue('carrier_id', ALL_CARRIERS_ID);
            toast.current?.show({
                severity: 'info',
                content: <ToastMessage title="Carrier Selection Reset to ALL" message="Selected carrier is not active during selected in force date" />,
            });
        }
    }, [activeCarriers, getValues, setValue]);

    useEffect(() => {
        // when the business unit changes we reset carrier and LOB format fields to defaults
        if (businessUnit) {
            setValue('carrier_id', ALL_CARRIERS_ID);
            setValue('lob_format', 'PC');
        }
    }, [businessUnit, setValue]);

    const onSubmit = async (data: FormValues) => {
        const { in_force_date, inception_date_after, inception_date_before, expiration_date_after, expiration_date_before, ...submitData } = data;
        try {
            await exportCatData({
                ...submitData,
                in_force_date: formatDateString.submit(in_force_date),
                inception_date_after: formatDateString.submit(inception_date_after),
                inception_date_before: formatDateString.submit(inception_date_before),
                expiration_date_after: formatDateString.submit(expiration_date_after),
                expiration_date_before: formatDateString.submit(expiration_date_before),
            }).unwrap();
        } catch (e) {
            toast.current?.show({
                severity: 'error',
                content: <ErrorToast title="An error occurred" errorMessage={e?.message ?? 'Something went wrong.'} />,
            });
        }
    };

    const onError = (errors: FieldErrors<FormValues>) => {
        Object.keys(errors).forEach((field: keyof FormValues) => {
            toast.current?.show({
                severity: 'error',
                content: <ErrorToast title="Could not submit request" errorMessage={errors[field]?.message ?? `${field} is invalid`} />,
            });
        });
    };

    return (
        <DefaultAccountLayout topPanelHeader="Cattools" hasActionPanel={false}>
            <Toast ref={toast} className="tw-absolute tw-top-0 tw-right-0" />
            <div className="ventus-content">
                <TaskTypePanel taskType="EXPORT_CAT_DATA" />
                <FormProvider {...formMethods}>
                    <PanelArea
                        actions={[
                            <BasicButton key="clear_filters" onClick={() => reset(getDefaultValues({}))} className="tw-bg-inherit tw-border-none tw-text-blue">
                                Clear Filters
                            </BasicButton>,
                        ]}
                    >
                        <div>
                            <h4>Business Unit:</h4>
                            <FormSelect<FormValues> name="business_unit" selections={businessUnits} />
                        </div>

                        <div>
                            <h4>Contract IDs:</h4>
                            <div className="tw-text-grey tw-text-2xs tw-pt-1">Please separate all policy numbers (contract ids) with a comma</div>
                            <FormInput<FormValues> name="policy_numbers" />
                        </div>

                        <div>
                            <h4>In Force On:</h4>
                            <FormDateInput<FormValues> name="in_force_date" rules={{ required: 'In Force Date must be provided' }} />
                        </div>

                        <div>
                            <h4>Inception Date:</h4>
                            <div className="date-group tw-items-center tw-gap-1">
                                {<span className="tw-text-[12px]">From</span>}
                                <FormDateInput<FormValues>
                                    name="inception_date_after"
                                    placeholder="Start date (optional)"
                                    maxBookingDate={inception_dates[1] ?? undefined}
                                    initialVisibleMonth={!inception_dates[0] && inception_dates[1] ? inception_dates[1] : undefined}
                                />
                                {<span className="tw-text-[12px]">to</span>}
                                <FormDateInput<FormValues>
                                    name="inception_date_before"
                                    placeholder="End date (optional)"
                                    minBookingDate={inception_dates[0] ?? undefined}
                                    initialVisibleMonth={!inception_dates[1] && inception_dates[0] ? inception_dates[0] : undefined}
                                />
                            </div>
                        </div>

                        <div>
                            <h4>Expiration Date:</h4>
                            <div className="date-group tw-items-center tw-gap-1">
                                {<span className="tw-text-[12px]">From</span>}
                                <FormDateInput<FormValues>
                                    name="expiration_date_after"
                                    placeholder="Start date (optional)"
                                    maxBookingDate={expiration_dates[1] ?? undefined}
                                    initialVisibleMonth={!expiration_dates[0] && expiration_dates[1] ? expiration_dates[1] : undefined}
                                />
                                {<span className="tw-text-[12px]">to</span>}
                                <FormDateInput<FormValues>
                                    name="expiration_date_before"
                                    placeholder="End date (optional)"
                                    minBookingDate={expiration_dates[0] ?? undefined}
                                    initialVisibleMonth={!expiration_dates[1] && expiration_dates[0] ? expiration_dates[0] : undefined}
                                />
                            </div>
                        </div>

                        {isVentusBU && (
                            <div>
                                <h4>Carrier:</h4>
                                <FormSelect<FormValues, 'carrier_id', number>
                                    name="carrier_id"
                                    selections={[{ value: ALL_CARRIERS_ID, display: 'All' }, ...activeCarriers]}
                                />
                            </div>
                        )}

                        {isVentusBU && (
                            <div>
                                <h4>LOB Format:</h4>
                                <FormSelect<FormValues> name="lob_format" selections={lobFormatChoices} />
                            </div>
                        )}

                        <div>
                            <h4>Risk View:</h4>
                            <FormSelect<FormValues> name="cat_view_type" selections={catViewChoices} />
                        </div>

                        <div>
                            <h4>Export Format:</h4>
                            <FormSelect<FormValues> name="cat_model_type" selections={catModelChoicesMap[currentBU]} />
                        </div>
                    </PanelArea>
                    <BasicButton
                        disabled={isSubmitting}
                        className="tw-p-2 default blue fit-content margin-top"
                        onClick={e => formMethods.handleSubmit(onSubmit, onError)(e)}
                    >
                        Export (Download)
                    </BasicButton>
                </FormProvider>
            </div>
        </DefaultAccountLayout>
    );
};
