import { createContextAndHelpers } from '@archinsurance-viki/property-jslib/src/hooks/context';
import { Toast } from 'primereact/toast';

const [ToastContext, ToastProvider, _useToastContext] = createContextAndHelpers<{ toast: React.MutableRefObject<Toast> }>();

export const useToastContext = () => {
    const toastContext = _useToastContext();
    return { toast: toastContext?.toast.current };
};
export { ToastContext, ToastProvider };
